import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "../src/Assets/css/custome.css";
import "../src/Assets/css/color.css";
import "../src/Assets/css/Education/style.css";
import "../src/Assets/css/miami.css";
import Home from "./Containers/Front/Home";
import React, { useEffect } from "react";
import { Suspense } from "react";
import { Skeleton } from "@mui/material";
import { base_url, isLogin, postHeader } from "./Helpers/request";
import { useLayoutEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { persistor } from "./Store/store";
import { isDesktop } from "react-device-detect";
import Education from "./Containers/Education/education";
import { Col, Container, Row } from "react-bootstrap";
import MiScrollToTop from "./Containers/Front/MiScrollToTop";
const Mainrouter = React.lazy(() => import("./mainrouter"));
const MainRequest = React.lazy(() => import("./Components/mainRequest"));
const MegaMenu = React.lazy(() => import("./Containers/Header/MegaMenu"));
const Header = React.lazy(() => import("./Containers/Header/Header"));
const Footer = React.lazy(() => import("./Containers/Footer/footer"));
const App = () => {
  const [currencydata, setCurrencydata] = useState([]);
  const [menudata, setMenudata] = useState([]);

  // useLayoutEffect(() => {
  //   //---------------------------- currency api
  //   axios
  //     .get(base_url + "/common/currency")
  //     .then((res) => {
  //       if (res.data.status == 1) {
  //         setCurrencydata(res.data.data);
  //         localStorage.getItem("bw-currency")
  //           ? localStorage.getItem("bw-currency")
  //           : localStorage.setItem(
  //               "bw-currency",
  //               res.data.data[0]?.currency_code
  //             );
  //         localStorage.getItem("bw-currency_img")
  //           ? localStorage.getItem("bw-currency_img")
  //           : localStorage.setItem(
  //               "bw-currency_img",
  //               res.data.data[0]?.country_flag
  //             );
  //         localStorage.getItem("bw-currencyicon")
  //           ? localStorage.getItem("bw-currencyicon")
  //           : localStorage.setItem(
  //               "bw-currencyicon",
  //               res.data.data[0]?.currency_icon
  //             );
  //       } else {
  //         console.log(res.data.message);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   //-----------------------------------menu
  //   axios
  //     .get(base_url + "/home/menu", {
  //       headers: postHeader,
  //     })
  //     .then((response) => {
  //       if (response.data.status == 1) {
  //         setMenudata(response.data.data);
  //       } else {
  //         toast.error(response.message, { autoClose: 3000 });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  //affirm script for dynamic messaging on product

  return (
    <>

      <Container className="mi_custom_body_container">
        <BrowserRouter>
          <Suspense
            fallback={
              <>
                <Skeltonheader />
              </>
            }
          >
             <MainRequest />
            {/* <Header menudata = {menudata}  currencydata={currencydata} />   */}
            <MegaMenu />
          </Suspense>
          <Switch>
            <Route exact path="/" component={Home} />
            <Suspense
              fallback={
                <>
                  <Skelton />
                </>
              }
            >
              <Route path="*" exact={true} component={Mainrouter} />
              {/* <Route path="/education" component={Education} /> */}
            </Suspense>
          </Switch>
          <Suspense
            fallback={
              <>
                <Skelton />
              </>
            }
          >
            <Footer />
            <MiScrollToTop />
          </Suspense>
        </BrowserRouter>
      </Container>
    </>
  );
};

// skelton conponent
const Skelton = () => {
  return (
    <>
      <div className="sk" style={{ margin: "15px 0px" }}>
        <Skeleton variant="text" animation="wave" />
      </div>
    </>
  );
};
const Skeltonheader = () => {
  return (
    <>
      <div className="skh" style={{ margin: "0px 0px 10px 0px" }}>
        <Skeleton variant="text" animation="wave" height={75} />
      </div>
    </>
  );
};
export default App;
